import { useEffect } from 'react';

import { useRenderContext } from '../../pages/home/context';
import ClientService from '../../../services/client';
import { handleReloadWithPreview, removePreviewParam } from '../../utils/handleReload';
import { COUPONS_INITIAL_SLIDE } from '../../utils/constants/common';

const useApplyCoupon = () => {
  const { apiBasePath, draftMode, isEditable, shopModel } = useRenderContext();
  const RestClientService = new ClientService(apiBasePath);

  const applyCoupon = async ({
    campaignId,
    code,
    index = 0,
  }) => {
    if (isEditable || draftMode) return { success: false, message: 'isEditable' };

    try {
      const response = await RestClientService.post(
        '/smart-coupons',
        {
          data: {
            campaignId,
            code,
          },
        },
        5000,
      );
      setTimeout(() => {
        handleReloadWithPreview({
          storefrontName: shopModel?.storefront?.name ?? '',
          additionalParams: [{ key: COUPONS_INITIAL_SLIDE, value: index }],
        });
      }, 150);
      return { success: true, ...response.data };
    } catch (error) {
      return { success: false, error };
    }
  };

  const getCampaignInfo = async ({
    campaignId,
  } = {}) => {
    try {
      if (isEditable || draftMode) return { success: false, message: 'isEditable' };

      const response = await RestClientService.get(
        '/smart-coupons',
        {
          params: {
            campaignId,
          },
        },
        5000,
      );
      return {
        success: true,
        ...response.data,
      };
    } catch (error) {
      return { success: false, error };
    }
  };

  useEffect(() => {
    removePreviewParam({
      additionalParams: [COUPONS_INITIAL_SLIDE],
    });
  }, []);

  return {
    applyCoupon,
    getCampaignInfo,
  };
};

export default useApplyCoupon;
