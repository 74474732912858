import React from 'react';
import PropTypes from 'prop-types';
import CouponCarousel from '@mshops-components-library/coupon-carousel';

import { useRenderContext } from '../../pages/home/context';

import useCouponModal from './useCouponModal';
import useApplyCoupon from './useApplyCoupon';

const CouponCarouselComponent = (props) => {
  const { isEditable, initialSlideCouponCarousel } = useRenderContext();
  const { visible, modal } = props;

  if (!visible) {
    return null;
  }

  const { applyCoupon, getCampaignInfo } = useApplyCoupon();
  const { modalUpdate, modalActions } = useCouponModal({
    isEditable,
    modal,
  });

  return (
    <CouponCarousel
      {...props}
      modal={modalUpdate}
      modalActions={modalActions}
      applyCoupon={applyCoupon}
      getCampaignInfo={getCampaignInfo}
      initialSlide={initialSlideCouponCarousel ?? 0}
    />
  );
};

CouponCarouselComponent.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  isEditable: PropTypes.bool,
  modal: PropTypes.shape({}),
};

export default CouponCarouselComponent;
