const getDeepLink = ({ storefrontName = '', url }) => {
  const urlEncode = encodeURIComponent(url);
  const BASE_DEEP_LINK = 'meli://webview/?webkit-engine=2';

  const QUERY_PARAMS = [
    '&hides_bottom_bar=false',
    '&search_mode=expanded',
    '&toolbar_elevation=none',
    '&cart_mode=default',
    '&navigationcp_mode=default',
    '&bar_elevation=none',
    '&refresh_mode=none',
    '&filter_checked=true',
    '&filter_id=storefront_url',
    `&url=${urlEncode}`,
    `&filter_value=${urlEncode}`,
  ];

  if (storefrontName) {
    QUERY_PARAMS.push(`&filter_name=${storefrontName}`);
  }

  const deepLink = `${BASE_DEEP_LINK}${QUERY_PARAMS.join('')}`;

  return {
    deepLink,
  };
};

export default getDeepLink;
