import { useState, useEffect } from 'react';
import { useRenderContext } from '../../pages/home/context';
import ClientService from '../../../services/client';
import { handleReloadWithPreview } from '../../utils/handleReload';

const KEY_MODAL_COUNT = 'CP_COUNT';
const KEY_MODAL_LAST_VIEW = 'CP_LAST_VIEW';
const VIEW_MODAL_COUNT = 3;

const useCouponModal = ({
  modal,
  isEditable,
}) => {
  if (isEditable || modal?.active === false) {
    return { modalUpdate: { ...modal, active: false } };
  }
  const [modalUpdate, setModalUpdate] = useState({ ...modal, active: false });
  const { shopModel: { storefront }, marketplaceInfo, apiBasePath, device, shopType } = useRenderContext();
  const { buyerId, ownerId } = marketplaceInfo;
  const last20Chars = storefront?.id?.slice(-20) || '';
  const KEY_COUNT_BY_STOREFRONT = `${KEY_MODAL_COUNT}_${last20Chars}`;
  const KEY_MODAL_LAST_BY_STOREFRONT = `${KEY_MODAL_LAST_VIEW}_${last20Chars}`;
  const TIME_TO_RELOAD = 2000;


  const clientService = new ClientService(apiBasePath);

  const setTodayMidnight = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  };

  const initializeModal = () => {
    localStorage.setItem(KEY_COUNT_BY_STOREFRONT, 1);
    localStorage.setItem(KEY_MODAL_LAST_BY_STOREFRONT, setTodayMidnight());
  };

  const updateModalCount = (count) => {
    const newCount = parseInt(count, 10) + 1;
    localStorage.setItem(KEY_COUNT_BY_STOREFRONT, newCount);
    localStorage.setItem(KEY_MODAL_LAST_BY_STOREFRONT, setTodayMidnight());
  };

  // control for modal view in client side
  useEffect(() => {
    try {
      const lastView = localStorage.getItem(KEY_MODAL_LAST_BY_STOREFRONT);
      const count = localStorage.getItem(KEY_COUNT_BY_STOREFRONT);

      if (!lastView || !count) {
        initializeModal();
        setModalUpdate(modalupdate => ({ ...modalupdate, active: true }));
      } else {
        const today = new Date().setHours(0, 0, 0, 0);
        const isNextDay = today > new Date(lastView);
        if (isNextDay) {
          if (count < VIEW_MODAL_COUNT) {
            setModalUpdate(modalupdate => ({ ...modalupdate, active: true }));
            updateModalCount(count);
          }
        }
      }
    } catch {
      setModalUpdate(modalupdate => ({ ...modalupdate, active: false }));
    }
  }, []);

  const onClickSecondary = () => {
    setModalUpdate(modalupdate => ({ ...modalupdate, active: false }));
  };

  const onClickPrimary = () => {
    clientService.post('/set-follower', {
      data: {
        ownerId,
        buyerId,
        entityId: storefront?.id,
        entityType: 'storefront',
        withAudience: true,
        queryParam: {
          source: 'modal',
          device,
          shopType,
          ownerId,
        },
      },
    })
      .then(() => {
        setTimeout(() => {
          handleReloadWithPreview({
            storefrontName: storefront?.name ?? '',
          });
        }, TIME_TO_RELOAD);
      })
      .catch(() => {});
    setModalUpdate(modalupdate => ({ ...modalupdate, active: false }));
  };

  return {
    modalUpdate,
    modalActions: {
      onClickSecondary,
      onClickPrimary,
    },
  };
};

export default useCouponModal;
